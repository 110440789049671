import React, { useState, useEffect } from "react";
import ApiService from "../Services/ApiService";
import ImageCardHold from "../Components/ImageCardHoldFlip";
import IProject from "../Interfaces/IProject";

interface ProgressData {
  projectId: number;
  progress: {
    userId: number;
    projectId: number;
    numberOfShares: number;
  }[];
}

export const Project = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [progressData, setProgressData] = useState<ProgressData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiService = new ApiService<IProject[]>(
        process.env.REACT_APP_CF_API_URL + "/project"
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setProjects(data);
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchProgressData = async () => {
      if (projects.length === 0) return;

      const miscProjects = projects.filter(
        (project) => project.type === "Misc"
      );
      const progressDataPromises = miscProjects.map(async (project) => {
        const progressApiService = new ApiService<number>(
          `${process.env.REACT_APP_CF_API_URL}/projectSubscription/${project.id}`
        );

        try {
          const response = await progressApiService.get();
          if (response.ok) {
            const data = await response.json();
            const newProgressData: ProgressData = {
              projectId: project.id,
              progress: data,
            };
            setProgressData(newProgressData);
          } else {
            console.error("Request for progress data failed:", response.status);
          }
        } catch (error: any) {
          console.error(
            "An error occurred while fetching progress data:",
            error.message
          );
        }
      });

      Promise.all(progressDataPromises).catch((error) => {
        console.error("An error occurred while fetching progress data:", error);
      });
    };

    fetchProgressData();
  }, [projects]);

  const rows: JSX.Element[][] = [];
  for (let i = 0; i < projects.length; i += 3) {
    let numberOfProjects = 0;

    if (progressData && "projectId" in progressData) {
      if (
        progressData.projectId === projects[i].id &&
        Array.isArray(progressData.progress)
      ) {
        numberOfProjects = progressData.progress.filter(
          (item) => item.projectId === projects[i].id
        ).length;
      }
    }

    const row = projects.slice(i, i + 3);
    rows.push(
      row.map((project) => (
        <div key={project.id} className="col-md-6 custom-top-margin">
          <a
            href={`/#/projectDetails/${project.id}`}
            style={{ textDecoration: "none", position: "relative" }}
          >
            <ImageCardHold
              imagePath={`${
                process.env.REACT_APP_CF_API_URL
              }/files/Projects/${encodeURIComponent(
                project.type
              )}/${encodeURIComponent(
                project.directoryName
              )}/${encodeURIComponent(project.frontCoverImage)}`}
              width={60}
              flipHold={project.type}
              name={project.title}
              price={`${project.price?.toLocaleString("fr-FR")} € - ${
                project.price
                  ? (project.price * 37.84).toLocaleString("fr-FR") + " ฿"
                  : ""
              }`}
              progressBar={
                project.type === "Misc"
                  ? project.numberOfShares - numberOfProjects
                  : (project.totalNumberOfShares * 100) / project.numberOfShares
              }
              valueP={
                project.type === "Misc"
                  ? Math.ceil((100 * numberOfProjects) / project.numberOfShares)
                  : 0
              }
              subTitle={project.subTitle}
            />
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                transform: "translate(-50%, 50%) rotate(-45deg)",
                background: "rgba(219, 10, 91, 0.6)",
                color: "white",
                padding: "5px 10px",
                borderRadius: "0 0 20px 0",
              }}
            >
              {project.type === "Flip" && "Achat revente"}
              {project.type === "Hold" && "Achat locatif"}
              {project.type === "Misc" && "Achat"}
            </div>
          </a>
        </div>
      ))
    );
  }

  return (
    <div>
      <div className="container mt-5">
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="row justify-content-start align-items-start"
          >
            {row}
          </div>
        ))}
      </div>
      <br />
    </div>
  );
};
