import React from "react";
import { Card } from "react-bootstrap";
import CustomCircularProgressbar from "./CustomCircularProgressbar";

const ImageCardHoldFlip = ({
  imagePath,
  width,
  flipHold,
  name,
  price = "",
  progressBar = 0,
  subTitle = "",
  valueP = 0,
}) => {
  const imageStyle = {
    width: "100%", // La largeur de l'image sera de 100%
    height: "255px", // La hauteur de l'image est calculée en fonction de la largeur
    borderRadius: "20px 20px 0 0", // Ajoutez d'autres styles d'image si nécessaire
  };

  return (
    <div
      style={{ width: `${width}%`, display: "flex", flexDirection: "column" }}
    >
      <Card style={{ borderRadius: "20px" }}>
        <Card.Img
          src={imagePath}
          alt="Placeholder"
          className="img-fluid"
          style={imageStyle} // Appliquez les styles ici
        />
        <Card.Body>
          <Card.Text>
            <h4 className="text-center">{name}</h4>
            <p className="text-center" style={{ marginLeft: "10px" }}>
              {subTitle}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomCircularProgressbar
                value={progressBar}
                flipHold={flipHold}
                valueMisc={valueP}
              />
            </div>
            <h5 className="text-center" style={{ margin: 0, marginTop: 15 }}>
              {price}
            </h5>
          </Card.Text>
        </Card.Body>
        <div
          style={{
            backgroundColor: "#38AAAE",
            borderRadius: "0 0 20px 20px",
            padding: "10px 0",
            textAlign: "center",
            color: "white",
          }}
        >
          {flipHold === "Flip" &&
            "9-18 mois | Rendement visé +18% | À partir de 500€"}

          {flipHold === "Hold" && "Détention long terme | Loyer visée 8% à 12%"}

          {flipHold === "Misc" && "Rentabilité > 10%"}
        </div>
      </Card>
    </div>
  );
};

export default ImageCardHoldFlip;
