import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../Images/logo4.png";

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la page vers le haut
  }, []); // Le tableau vide signifie que cet effet s'exécute une seule fois après le rendu initial

  return (
    <footer className="custom-footer text-white">
      <br />
      <Container>
        <Row>
          <Col md={3}>
            {/* Première colonne */}
            <img src={logo} alt="Invest in Thailand" />
          </Col>
          <Col md={3}>
            {/* Deuxième colonne */}
            <h5>Legal</h5>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <a
                  href={`#/cgu?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Conditions générales d'utilisation
                </a>
              </li>
              <li>
                <a
                  href={`#/dataUsePolicy?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Protection des données
                </a>
              </li>
              <li>
                <a
                  href={`#/legalNotice?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Mentions légales
                </a>
              </li>
              <li>
                <a
                  href={`#/cookies?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Gestion des cookies
                </a>
              </li>
              <li>
                <a
                  href={`#/risk?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Risques
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            {/* Troisième colonne */}
            <h5>Entreprise</h5>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <a
                  href={`#/about?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Qui sommes nous
                </a>
              </li>
              <li>
                <a
                  href={`#/thailand?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Le marché Thailandais
                </a>
              </li>
              <li>
                <a
                  href={`#/project?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Projets d'investissements
                </a>
              </li>
              <li>
                {" "}
                <a
                  href={`#/hold?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Fonctionnement Achat locatif
                </a>
              </li>
              <li>
                {" "}
                <a
                  href={`#/flip?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Fonctionnement Achat revente
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            {/* Quatrième colonne */}
            <h5>Divers</h5>
            <ul style={{ listStyleType: "none" }}>
              <li>
                {" "}
                <a
                  href={`#/faq?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href={`#/testimony?scroll=top&timestamp=${Date.now()}`}
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Témoignages
                </a>
              </li>
              <li>
                <a
                  href="mailto:contact@edenroc-immobilier.com?subject=[Invest in Thaland] - Informations&body=Bonjour"
                  style={{ color: "white" }}
                >
                  Contactez-nous
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={12} className="text-center">
            <a href="#">Lien du pied de page</a>
            <br />© Copyright 2023 Invest in Thailand
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default Footer;
