// UserContext.js
import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  // Fonction pour valider les propriétés de l'utilisateur
  const validateUserProperties = (user) => {
    for (const property in user) {
      if (
        property !== "projectIds" &&
        property !== "registrationToken" &&
        property !== "password" &&
        !user[property]
      ) {
        return false;
      }
    }
    return true;
  };

  const isUserComplete = validateUserProperties(user);

  return (
    <UserContext.Provider value={{ user, setUser, isUserComplete }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
