import React from "react";
import { Card } from "react-bootstrap";

const ImageCard = ({ imagePath, title = "", subTitle = "", price = "" }) => {
  return (
    <Card style={{ borderRadius: "20px" }}>
      <Card.Img
        src={imagePath}
        alt="Placeholder"
        className="img-fluid"
        style={{ borderRadius: "20px 20px 0 0" }}
      />
      <Card.Body>
        <Card.Text>
          <h3 className="text-center">{title}</h3>
          <h4 className="text-center" style={{ margin: 0 }}>
            {subTitle}
          </h4>
          <h5 className="text-center" style={{ margin: 0, marginTop: 15 }}>
            {price}
          </h5>
        </Card.Text>
      </Card.Body>
      <div
        style={{
          backgroundColor: "#38AAAE",
          borderRadius: "0 0 20px 20px",
          padding: "10px 0",
          textAlign: "center",
          color: "white",
        }}
      >
        De 9 à 18 mois | Rendement visé 12% | À partir de 15 000€
      </div>
    </Card>
  );
};

export default ImageCard;
