import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import VideoEmbed from "../Components/VideoEmbed";
import { Card } from "react-bootstrap";
import { BsArrowRightCircle } from "react-icons/bs";
import { RiAlarmWarningLine } from "react-icons/ri";
import CarouselImages from "../Components/CarouselImages";
import Image from "../Components/Image";
import { BsFillPinMapFill, BsPeople, BsFiletypePdf } from "react-icons/bs";
import { GiModernCity, GiCityCar, GiCommercialAirplane } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { FcMoneyTransfer } from "react-icons/fc";
import SharesDropdown from "../Components/SharesDropdown";
import MiscDropdown from "../Components/MiscDropdown";
import { useUser } from "../Contexts/UserContext";
import ApiService from "../Services/ApiService";
import IProject from "../Interfaces/IProject";
import { iconMappings } from "../Utils/IconMappings";
import GenerateListWithIcons from "../Components/generateListWithIcons";
import { useParams, Link } from "react-router-dom";

export const ProjectDetails = () => {
  const { user, setUser, isUserComplete } = useUser();
  const [selectedValue, setSelectedValue] = useState(1); // Valeur sélectionnée dans la dropdownlist
  const [share, setShare] = useState(0);
  const [data, setData] = useState<IProject | null>(null);
  const { projectId } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStyle, setAlertStyle] = useState("");
  const [subscriptionDone, setSubscriptionDone] = useState(false);

  const handleDropdownChange = (selectedValue: number) => {
    if (data?.share !== undefined) {
      if (data?.type === "Misc") {
        setShare(data?.share);
      } else {
        setShare(selectedValue * data?.share);
      }
      setSelectedValue(selectedValue);
    }
  };

  const handleReserveClick = async () => {
    try {
      const reservationData = {
        userId: user.id,
        projectId: projectId,
        NumberOfShares: selectedValue,
      };

      const authToken = user.token;

      const apiService = new ApiService<any>(
        process.env.REACT_APP_CF_API_URL + "/ProjectSubscription",
        authToken
      );

      const response = await (
        await apiService.post("", reservationData)
      ).json();

      if (response) {
        setAlertMessage("Nous avons enregistrés votre demande");
        setAlertStyle("success");
        setShowAlert(true);
        setSubscriptionDone(true);

        const updatedUser = { ...user };
        updatedUser.projectIds += "," + projectId;
        setUser(updatedUser);
      }

      // Optional: Perform any other actions after successful reservation
    } catch (error) {
      console.error("Error while reserving shares:", error);
      // Handle error state
    }
  };

  useEffect(() => {
    console.log(isUserComplete);
    const fetchData = async () => {
      const apiService = new ApiService<IProject>(
        `${process.env.REACT_APP_CF_API_URL}/project/${projectId}`
      );

      try {
        const response = await apiService.get();
        if (response.ok) {
          const data = await response.json();
          setData(data);

          if (user.projectIds && user.projectIds.includes(projectId)) {
            setSubscriptionDone(true);
            setAlertMessage("Vous avez déjà souscrit.");
            setAlertStyle("success");
            setShowAlert(true);
          }
        } else {
          console.error("Request failed:", response.status);
        }
      } catch (error: any) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 row justify-content-center align-items-start">
            <CarouselImages
              imageNames={data?.projectImages}
              url={`${process.env.REACT_APP_CF_API_URL}/files/Projects/${data?.type}/${data?.directoryName}/`}
            />
            {user && isUserComplete && !subscriptionDone ? (
              // Première condition : Utilisateur connecté, formulaire rempli, et pas d'abonnement
              <div>
                <br />
                {data?.type === "Misc" ? (
                  <MiscDropdown onChange={handleDropdownChange} />
                ) : (
                  <SharesDropdown onChange={handleDropdownChange} />
                )}{" "}
                <b>soit : {share.toLocaleString("fr-FR")} €</b> <br />
                <br />
                <div className="col-md-6 custom-top-margin d-flex justify-content-end">
                  <Button variant="success" onClick={handleReserveClick}>
                    Réserver
                  </Button>
                </div>
              </div>
            ) : user && !isUserComplete ? (
              <>
                <p className="text-center" style={{ marginTop: "15px" }}>
                  Pour réserver des parts il faut compléter son compte.
                  <br />
                  <br />
                  <Link to="/account">
                    <Button variant="primary" style={{ marginLeft: "10px" }}>
                      Mon compte
                    </Button>
                  </Link>
                </p>
              </>
            ) : (
              !subscriptionDone && (
                <p style={{ margin: 50, fontSize: 20 }} className="text-center">
                  <Link to="/signin">
                    <Button variant="outline-primary">Se connecter</Button>
                  </Link>
                  <Link to="/signup">
                    <Button variant="primary" style={{ marginLeft: "10px" }}>
                      Créer mon compte
                    </Button>
                  </Link>
                </p>
              )
            )}
            {showAlert && (
              <div
                className={`alert alert-${alertStyle} text-center`}
                style={{ marginTop: "10px" }}
                role="alert"
              >
                {alertMessage}
              </div>
            )}
          </div>
          <div className="col-md-6 custom-top-margin">
            <h2 style={{ margin: 30 }}>{data?.title}</h2>{" "}
            <p>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  &nbsp;Prix : &nbsp; {data?.price.toLocaleString("fr-FR")} € -{" "}
                  {data && data.price
                    ? (data.price * 37.84).toLocaleString("fr-FR")
                    : ""}{" "}
                  ฿
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <GenerateListWithIcons
                      data={data}
                      property="description"
                      iconMappings={iconMappings}
                    />
                    <hr />
                    <FcMoneyTransfer style={{ marginRight: "10px" }} /> Montant
                    de la part : {data?.share.toLocaleString("fr-FR")} € 
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
            <p>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  Rentabilité
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <GenerateListWithIcons
                      data={data}
                      property="profitability"
                      iconMappings={iconMappings}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
            <p>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  Documents à télécharger <BsFiletypePdf />
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {user ? (
                      <ul
                        style={{
                          listStyleType: "none",
                          paddingInlineStart: "0",
                        }}
                      >
                        <li style={{ display: "flex", alignItems: "center" }}>
                          <BsArrowRightCircle style={{ marginRight: "10px" }} />
                          <p style={{ margin: 0 }}>
                            <a
                              href={`${process.env.REACT_APP_CF_API_URL}/files/Projects/${data?.type}/${data?.directoryName}/${data?.documents}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              Télécharger le PDF
                            </a>
                          </p>
                        </li>
                      </ul>
                    ) : (
                      !user && (
                        <p className="text-center" style={{ color: "red" }}>
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <RiAlarmWarningLine
                              style={{ marginRight: "10px", fontSize: "24px" }}
                            />
                            Veuillez vous enregistrer et compléter votre profil
                            pour télécharger le pdf.
                          </span>
                          <br />
                          <Link to="/signin">
                            <Button variant="outline-primary">
                              Se connecter
                            </Button>
                          </Link>
                          <Link to="/signup">
                            <Button
                              variant="primary"
                              style={{ marginLeft: "10px" }}
                            >
                              Créer mon compte
                            </Button>
                          </Link>
                        </p>
                      )
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 row justify-content-center align-items-start">
            <p>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  Mise en valeur
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <GenerateListWithIcons
                      data={data}
                      property="enhancements"
                      iconMappings={iconMappings}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
          </div>
          <div className="col-md-6 custom-top-margin">
            <VideoEmbed
              videoUrl={`${process.env.REACT_APP_CF_API_URL}/files/Projects/${data?.type}/${data?.directoryName}/${data?.video}`}
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 row justify-content-center align-items-start">
            <p style={{ marginTop: "50px" }}>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  Environnement du projet
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <GenerateListWithIcons
                      data={data}
                      property="environment"
                      iconMappings={iconMappings}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
          </div>
          <div className="col-md-6 custom-top-margin">
            {data && data.type && data.directoryName && data.map !== "" && (
              <Image
                imagePath={`${process.env.REACT_APP_CF_API_URL}/files/Projects/${data.type}/${data.directoryName}/${data.map}`}
              />
            )}
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center align-items-start">
          <div className="col-md-6 row justify-content-center align-items-start">
            <p>
              <Card style={{ borderRadius: "20px" }}>
                <Card.Header
                  style={{ backgroundColor: "#38AAAE", color: "white" }}
                >
                  Points forts du projet
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <GenerateListWithIcons
                      data={data}
                      property="strength"
                      iconMappings={iconMappings}
                    />
                  </Card.Text>
                </Card.Body>
              </Card>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
