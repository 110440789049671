import React, { useState } from "react";

interface DropdownListProps {
  onChange: (selectedValue: number) => void;
}

const MiscDropdown: React.FC<DropdownListProps> = ({ onChange }) => {
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    undefined
  );

  const handleSelectChange = (newValue: number) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const dropdownItems = [];
  for (let i = 1; i <= 30; i++) {
    dropdownItems.push(
      <a
        key={i}
        className="dropdown-item"
        onClick={() => handleSelectChange(i)}
      >
        {i}
      </a>
    );
  }

  return (
    <>
      <b>Numéro de la maison souhaitée (cf PDF) : </b>{" "}
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-bs-toggle="dropdown"
        >
          {selectedValue !== undefined ? selectedValue : 0}
        </button>
        <div
          className="dropdown-menu"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          {dropdownItems}
        </div>
      </div>
    </>
  );
};

export default MiscDropdown;
